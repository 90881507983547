import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ProjectService} from "../project.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public elementId = '';
  constructor(private router: Router, private _projectService: ProjectService) { }

  ngOnInit() {
    this._projectService.currentId.subscribe(footerid => this.elementId = footerid);
  }

}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ProjectService} from "../project.service";
import {ForgeService} from "../shared/forge.service";
import {filter, map} from "rxjs/operators";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isActive(instruction: any[]): boolean {
    return this.router.isActive(this.router.createUrlTree(instruction), false);
  }

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private projectService: ProjectService,
              private forgeService: ForgeService) { }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary')
    )
      .subscribe( route => {
        this.projectService.bucket = route.snapshot.params['bucket'];
        this.projectService.fileid = route.snapshot.params['fileid'];
        this.forgeService.bucket = route.snapshot.params['bucket'];
        this.forgeService.fileid = route.snapshot.params['fileid'];
//          this.forgeService.fileid = route.params.getValue('fileid').fileid;
      });
  }

}

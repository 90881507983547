import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { ProjectComponent } from './project.component';
import { ProjectService } from './project.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ThreeDComponent } from './three-d/three-d.component';
import { FiveDComponent } from './five-d/five-d.component';
import { CookieService } from 'ngx-cookie-service';
import { CallbackComponent } from './callback/callback.component';
import { HttpClientModule } from '@angular/common/http';
import {ViewerModule} from "ng2-adsk-forge-viewer";
import {TreeTableModule} from "primeng/primeng";
import {ToolsComponent} from "./tools/tools.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

const appRoutes: Routes = [
  { path: 'callback/:token/:redirect', component: CallbackComponent },
  { path: ':bucket/:fileid/3d5d', component: ThreeDComponent },
  { path: ':bucket/:fileid/5d', component: FiveDComponent },
  { path: ':bucket/:fileid/tools', component: ToolsComponent}
];


@NgModule({
  declarations: [
    AppComponent,
    ProjectComponent,
    FooterComponent,
    HeaderComponent,
    ThreeDComponent,
    FiveDComponent,
    ToolsComponent,
    CallbackComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes,
      {paramsInheritanceStrategy: 'always', enableTracing: true}
    ),
    ViewerModule,
    TreeTableModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [ProjectService, CookieService],
  bootstrap: [AppComponent]

})
export class AppModule { }

import {Component, OnInit} from '@angular/core';
import {ForgeServiceResult} from "../shared/ForgeServiceResult";
import {TreeNode} from "primeng/primeng";
import deepMapKeys = require("deep-map-keys");
import {ForgeService} from "../shared/forge.service";
import {FlattenedArray} from "../shared/flattened-array";
import {ExcelService} from "../shared/excel.service";


@Component({
  selector: 'app-five-d',
  templateUrl: './five-d.component.html',
  styleUrls: ['./five-d.component.css']
})
export class FiveDComponent implements OnInit {
  public sigmaData = <any>{};
  public forgeServiceResult = <ForgeServiceResult>{sigmaData: {}, IdList: []};
  files: TreeNode[];
  cols: any[];
  flattenedArray: FlattenedArray[] = [];
  estimateChosen: boolean;

  constructor(public _forgeService: ForgeService, public _excelService: ExcelService) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'Text', header: 'Text', width: '50%'},
      {field: 'Qty', header: 'Quantity', width: '15%'},
      {field: 'Units', header: 'Units', width: '10%'},
      {field: 'Cost', header: 'Cost', width: '25%'}
    ];
    if (this._forgeService.checkData()) {
      this.forgeServiceResult = this._forgeService.getData();
      this.sigmaData = this.forgeServiceResult.sigmaData;
      this.populateDataNodes(this.sigmaData.components);
      this.estimateChosen = true;
    } else {
      this._forgeService.getDataFromBIM(this._forgeService.getToken()).subscribe(res => {
          this.forgeServiceResult.sigmaData = res;
          this.sigmaData = this.forgeServiceResult.sigmaData;
          let resultList = [];
          this.forgeServiceResult.IdList = this._forgeService.parseData(this.forgeServiceResult.sigmaData.components, resultList);
          this._forgeService.setData(this.forgeServiceResult);
          this.populateDataNodes(this.sigmaData.components);
          this.estimateChosen = true;
        },
        (error) => {
          if (error.status == 401) {
            alert("You were not authorized by BIM360. Try reloading the page?");
            this._forgeService.reset();
          } else {
            alert("Error getting data from BIM360? Please make sure you are member on the BIM360 Project.");
          }
        });
    }

  }
  public populateDataNodes(sigmaData: any) {
    this.files = <TreeNode[]>deepMapKeys(sigmaData, replaceKeys);
    this.flattenedArray = [];
    this.flattenArray(this.files);
  }

  public exportToExcel(){
    this._excelService.exportAsExcelFile(this.flattenedArray, "ExportedData");
  }

  public changeTab(event: any){
    let thenum = event.nextId.replace( /^\D+/g, '');
    if(thenum === '0'){
      this.populateDataNodes(this.sigmaData.components);
      this.estimateChosen = true;
    } else {
      this.populateDataNodes(this.sigmaData.insights[thenum-1].components);
      this.estimateChosen = false;
    }
  }

  public flattenArray(array: any[], level = 0){
    let key, value;
    for(key in array){
      value = array[key];
      if(typeof value === 'object'){
        if(isDefined(value.data)){
          this.flattenedArray.push({
            Text: this.addWhiteSpacesToString(value.data.Text, level),
            Quantity: value.data.Qty,
            Units: value.data.Units,
            UnitCost: value.data.Cost,
            ProjectCost: value.data.absCost,
            ProjectQuantity: value.data.absQty
          });
        }
        value = this.flattenArray(value, isDefined(value.data) ? level + 1: level);
      }
    }
  }

  public addWhiteSpacesToString(input: string, level: number): string{
    input = '  '.repeat(level) + input;
    return input;
  }

}

function replaceKeys(key) {
  return key.replace(/^components$/, "children").replace(/^s$/, "data");
}

function isDefined<T>(value: T | undefined | null): value is T {
  return <T>value !== undefined && <T>value !== null;
}

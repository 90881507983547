import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {ForgeService} from "../shared/forge.service";


@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private _forgeService: ForgeService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
        this._forgeService.storeToken(params['token']);
        window.location.href = atob(params['redirect']);
    });

  }

}

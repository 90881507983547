import { map, filter, catchError, mergeMap } from 'rxjs/operators';

import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import { ProjectService } from './/project.service';
import { NgbTabset} from "@ng-bootstrap/ng-bootstrap";
import {ForgeService} from "./shared/forge.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Construction Insight';
  public projectName: string;

  @ViewChild('tabMenu', {static: false})
  private tabMenu:NgbTabset;

  isActive(instruction: any[]): boolean {
    return this.router.isActive(this.router.createUrlTree(instruction), false);
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private forgeService: ForgeService
  ) {

    }



  ngOnInit() {

      this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          filter((route) => route.outlet === 'primary')
      )
      .subscribe( route => {
          this.projectService.bucket = route.snapshot.params['bucket'];
          this.projectService.fileid = route.snapshot.params['fileid'];
          this.forgeService.bucket = route.snapshot.params['bucket'];
          this.forgeService.fileid = route.snapshot.params['fileid'];
//          this.forgeService.fileid = route.params.getValue('fileid').fileid;
          });
  }

}

import {Component, OnInit} from '@angular/core';
import {ForgeServiceResult} from "../shared/ForgeServiceResult";
import {
  GeometryLoadedEventArgs,
  SelectionChangedEventArgs,
  ViewerInitializedEvent,
  ViewerOptions
} from "ng2-adsk-forge-viewer";
import {TreeNode} from "primeng/api";
import deepMapKeys = require("deep-map-keys");
import {ForgeService} from "../shared/forge.service";

declare const THREE: any;

@Component({
  selector: 'five-d',
  templateUrl: './three-d.component.html',
  styleUrls: ['./three-d.component.css']
})
export class ThreeDComponent implements OnInit {
  public content: any;
  private sigmaData = <any>{};
  public viewerOptions: ViewerOptions;
  public forgeServiceResult = <ForgeServiceResult>{sigmaData: {}, IdList: []};
  public setSigmaData;
  cols: any[];
  files: TreeNode[];
  private readmore = false;
  public modelPartSelected = false;

  constructor(public _forgeService: ForgeService) {
    this.setSigmaData = (data) => {
      this.sigmaData = data;
      if (this.sigmaData.s.Notes.length > 99) {
        this.sigmaData.s.Notes = this.sigmaData.s.Notes.substring(0, 100);
        this.readmore = true;
      } else {
        this.readmore = false;
      }
    }
  }


  ngOnInit() {
    this.cols = [
      {field: 'Text', header: 'Text', width: '50%'},
      {field: 'Qty', header: 'Quantity', width: '15%'},
      {field: 'Units', header: 'Units', width: '10%'},
      {field: 'Cost', header: 'Cost', width: '25%'}
    ];

    if (this._forgeService.checkData()) {
      this.forgeServiceResult = this._forgeService.getData();
      this.sigmaData = this.forgeServiceResult.sigmaData;
      this.loadViewer();
    } else {
      this._forgeService.getDataFromBIM(this._forgeService.getToken()).subscribe(res => {
          this.forgeServiceResult.sigmaData = res;
          this.sigmaData = this.forgeServiceResult.sigmaData;
          let resultList = [];
          this.forgeServiceResult.IdList = this._forgeService.parseData(this.forgeServiceResult.sigmaData.components, resultList);
          this._forgeService.setData(this.forgeServiceResult);
          this.loadViewer();
        },
        (error) => {
          if (error.status == 401) {
            alert("You were not authorized by BIM360. Try reloading the page?");
            this._forgeService.reset();
          } else {
            alert("Error getting data from BIM360? Please make sure you are member on the BIM360 Project.");
          }
        });
    }
  }

  public loadViewer() {
    this.viewerOptions = {
      initializerOptions: {
        env: 'AutodeskProduction',
        getAccessToken: (onGetAccessToken: (token: string, expire: number) => void) => {
          const expireTimeSeconds = 60 * 30;
          onGetAccessToken(this._forgeService.getToken(), expireTimeSeconds);
        },
        api: 'derivativeV2'
      },
      viewerConfig: {
        //extensions: ['SigmaExtension']
      },
      onViewerScriptsLoaded: () => {
        // Register a custom extension
        //Extension.registerExtension('SigmaExtension', SigmaExtension);
      },
      onViewerInitialized: (args: ViewerInitializedEvent) => {
        args.viewerComponent.DocumentId = this.forgeServiceResult.sigmaData.modelURN;
      },
      enableMemoryManagement: true
    };
  }

  public selectionChanged(event: SelectionChangedEventArgs) {
    if (event.dbIdArray.length > 0) {
      event.target.setSelectionColor(new THREE.Color(0.38, 0.66, 0.17), Autodesk.Viewing.SelectionMode.REGULAR);
      this.modelPartSelected = true;
      this.setSigmaData(this.forgeServiceResult.IdList[event.dbIdArray.toString()]);
      this.populateDataNodes([this.forgeServiceResult.IdList[event.dbIdArray.toString()]]);
    } else {
      this.modelPartSelected = false;
    }
  }

  public onGeometryLoaded(event: GeometryLoadedEventArgs) {
    event.target.setOptimizeNavigation(false);
    event.target.setQualityLevel(false, false);
    event.target.setGroundShadow(false);
    event.target.setGroundReflection(false);
    event.target.setProgressiveRendering(false);
    event.target.setGroundShadow(false);
  }

  public populateDataNodes(sigmaData: any) {
    this.files = <TreeNode[]>deepMapKeys(sigmaData, replaceKeys);
  }
}

function replaceKeys(key) {
  return key.replace(/^components$/, "children").replace(/^s$/, "data");
}



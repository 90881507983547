import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProjectService} from "../project.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {
  public emailForm: FormGroup;
  public emailObject: EmailObject;
  public submitHasBeenCalled: boolean;

  constructor() {
  }

  ngOnInit() {
    this.initForm();
    this.initEmailObject();
  }

  private initForm(): void {
    this.emailForm = new FormGroup({
      'emailText': new FormControl(''),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern('(?:[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+)+(?:\@[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]{2,})')
      ])
    })
  }

  private initEmailObject(): void {
    this.emailObject = {
      emailText: '',
      email: ''
    }
  }

  public get getEmailTextControl() {
    return this.emailForm.get('emailText');
  }

  public get getEmailControl() {
    return this.emailForm.get('email');
  }

  public onSubmit(): void {
    this.submitHasBeenCalled = true;
    this.getEmailControl.markAsDirty();
    this.getEmailTextControl.markAsDirty();

    if(this.getEmailControl.valid && this.getEmailTextControl.valid){
      let link = window.location.origin + window.location.pathname.replace("tools", "3d5d");
      window.location.href = 'mailto:' + this.emailObject.email + '?subject=Construction%20Insight%20link%20shared&body=' + link + '%0A' + encodeURI(this.emailObject.emailText);
    }

  }

}

export interface EmailObject {
  emailText?: string,
  email: string
}

import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from "rxjs";
import {ForgeServiceResult} from "./ForgeServiceResult";

@Injectable({
  providedIn: 'root'
})
export class ForgeService {
  private token = '';
  public sigmaData = <any>{};
  public idList = [];
  public bucket = '';
  public fileid = '';
  private dataFetched = false;

  constructor(private cookieService: CookieService, private http: HttpClient) {
      this.token = this.cookieService.get('forgeAccessToken');
  }

  public getToken() {
      if (this.check()) {
          return this.token;
      }
  }

  public setData(incommingData: ForgeServiceResult){
    this.sigmaData = incommingData.sigmaData;
    this.idList = incommingData.IdList;
  }

  public checkData(): boolean{
    if(this.check()){
      return this.sigmaData.modelURN !== '' && this.idList.length > 0;
    }
  }

  public getData(): ForgeServiceResult{
    if (this.check()) {
      return <ForgeServiceResult> {sigmaData: this.sigmaData, IdList: this.idList};
    }
  }

  public storeToken(token: string) {
      const d = new Date(0);
      d.setUTCSeconds(JSON.parse(atob(token.split(".")[1])).exp);
      this.cookieService.set('forgeAccessToken', token, d, '/');
  }

  public check() {
      if (this.token == '') {
        window.location.href = 'https://developer.api.autodesk.com/authentication/v1' +
            '/authorize?response_type=code&client_id=' + 'u3j1dG1NmxxuJg9X6G7tsGjVt7Ams6v0' +
            '&redirect_uri=http%3A%2F%2Fsigmaservice.net%2Ftools%2Foauth2%2Fv1%2Fadskforge%2F' +
            '&scope=data:read&state=sigma5d!' + window.location.protocol + "//" + window.location.host +
            '!' + window.location;
      } else {
        return true;
      }
  }

  public reset() {
      this.cookieService.delete('forgeAccessToken', '/');
      this.token = '';
      this.dataFetched = false;
      this.check();
  }

public getDataFromBIM(token: string): Observable<Object>{
  const endpoint = 'https://developer.api.autodesk.com/oss/v2/buckets/';
  const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type':  'application/json'
    })
  };
  let url = endpoint + this.bucket + '/objects/' + this.fileid;
  return this.http.get(url, httpOptions);
}

  public parseData(componentArray: any[], resultList: any[]): any[] {
    for (let i = 0; i < componentArray.length; i++) {
      if (typeof componentArray[i].c.RevitID !== "undefined") {
        resultList[componentArray[i].c.RevitID] = componentArray[i];
      }
      this.parseData(componentArray[i].components, resultList);
    }
    return resultList;
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  public projectName = '';
  public bucket;
  public fileid;

  private newIdForFooter = new BehaviorSubject('3dModelFooter');
  currentId = this.newIdForFooter.asObservable();

  constructor() {
  }
}

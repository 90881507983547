import { Component } from '@angular/core';
import { ProjectService } from './/project.service';

@Component({
  selector: 'project-info',
  template: '<h3>Project: {{projectService.projectName}}</h3>'
})
export class ProjectComponent {
  constructor(
    private projectService: ProjectService
  ) {}
}
